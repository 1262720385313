.transform-component-module_wrapper__1_Fgj {
  overflow: hidden !important;
}

.transform-component-module_content__2jYgh {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;

  .pin {
    position: absolute;
    width: 20px;
    height: 20px;
    //background-color: #a21d1d;
    //border: 2px solid #721515;
    border-radius: 50%;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      //background-color: #e44e4e;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.carousel-container {
  flex: 0 0 30%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  border-right: 1px solid #ddd;
}

.group-container {
  flex: 1;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  height: auto;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.left-controls-container {
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: inline-flex;
  width: 100%;
}

.canvas-outer-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}

.canvas-inner-container {
  transform-origin: 0 0;
  width: 75%;
  height: 100%;
}

.zoom-controls {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;

  button {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #333;

    &:hover {
      background-color: #f0f0f0;
    }

    &:first-child {
      border-bottom: 1px solid #ddd;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  span {
    padding: 5px;
    font-size: 14px;
    color: #333;
    text-align: center;
  }
}

canvas {
  //border: 1px solid #ccc;
}

.pin {
  position: absolute;
  //background-color: red;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &.selected {
    box-shadow: 0 0 10px 5px rgba(255, 0, 0, 0.8);
  }
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;

  &-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    max-width: 100%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 550px;
    @media (max-width: 768px) {
      width: 100%;
      height: 100vh;
    }
  }
  &-header {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  &-title {
    margin: 0;
    padding: 0;
    display: flex;
  }

  button {
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    display: flex;
    align-self: end;
    transition: background-color 0.3s ease;
  }
  .close-icon {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    display: flex;
  }
  .delete-button {
    margin-top: 10px;
  }

  .modal-image-container {
    position: relative;
    width: 100%;
    padding-top: 75%; // 4:3 aspect ratio
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border: 2px solid #686868;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-size: 18px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }

  .image {
    max-height: 70vh;
    object-fit: contain;
  }

  .delete-button {
    background-color: #7c3aed;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
}
