.title {
  padding: 24px 0;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  min-width: 600px;
  max-width: 60%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    min-height: 100%;
  }
}

.closeButton {
  position: absolute;
  top: 2em;
  color: black;
  font-weight: 200;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
