.container {
  padding: 2rem 4rem;
  width: 100%;
}
.breadcrumbs {
  padding: 0 6rem;
}

.heading-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: center;
  padding: 0 6rem;
  .save-button {
    background-color: #7C3AED;
    color: var(--color-white);
    &:hover {
      background-color: var(--color-purple--100);
      color: var(--color-purple--700);
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.content-container {
  display: flex;
  gap: 1rem;
  @media (max-width: 1368px) {
    flex-direction: column;
  }

  .editable-heading {
    display: flex;
    align-items: center;
    flex-flow: row;
    font-family: Arial, sans-serif;

    h1 {
      font-size: 32px;
      font-weight: bold;
      color: #333;
      margin-right: 10px;
      outline: none;
    }

    svg {
      width: 24px;
      height: 24px;
      color: #8e44ad;
    }
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}


