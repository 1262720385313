.selectWrapper {
  display: inline-block;
  //border: 1px solid #ccc;
  //border-radius: 4px;
  background-color: white;
  //padding: 8px 12px;
  //font-family: var(--font-family--body), sans-serif;
  z-index: 2;
  //bottom: 2rem;
  //left: 2rem;
  //position: relative;
  //font-weight: normal;
  margin-bottom: 0;
}

.select {
  appearance: none;
  background: transparent;
  border: none;
  font-size: 16px;
  padding-right: 24px;
  cursor: pointer;
}

.selectWrapper::after {
  //content: "▼";
  //top: 0;
  //right: 2rem;
  transform: translateY(-50%);
  //pointer-events: none;
  //font-size: 36px;
}