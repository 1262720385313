.container {
  width: fit-content;
  bottom: 2rem;
  left: 12rem;
  padding: 2px 2px 2px 4px;
  z-index: 2;
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
    right: 0;
    top: 2rem;
    bottom: unset;
    left: unset;
  }
}

.button {
  background-color: white;
  border: none;
  color: #000;
  padding: 8px 10px;
  border-radius: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: var(--font-family--body);
  transition: background-color 0.3s ease;
  width: 96px;
  height: 2.25rem;
  &:hover {
    background-color: #e0e0e0;
  }
  &.active {
    background-color: #D5D5D5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  &:nth-child(1) {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  &:nth-child(2) {
    border-left: 2px solid #B6B9BF;
    border-right: 2px solid #B6B9BF;
  }
  &:nth-child(3) {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  @media (max-width: 1024px) {
    border: none !important;
    border-radius: 0 !important;
  }
}